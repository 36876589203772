import {BrowserRouter as Router} from 'react-router-dom';
import useRoutes from "./routes";
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {useContext, useEffect} from "react";
import AuthContext from "./context/authContext";

function App() {
    const {auth} = useContext(AuthContext);
    const routes = useRoutes(auth);
    useEffect(() => {
        let l = localStorage.getItem("lang");
        if (l === null){
            localStorage.setItem("lang", "ru");
            window.location.reload();
        }
    }, [localStorage]);
      return (
          <div>
            <Router>
                {!auth && <Header/>}
                  <div>
                    {routes}
                  </div>
                {!auth && <Footer/>}
            </Router>
          </div>
      );
}

export default App;
