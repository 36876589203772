export const AppStoreLogo = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="40" viewBox="0 0 120 40" fill="none">
            <g clipPath="url(#clip0_755_3060)">
                <mask id="mask0_755_3060" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="40">
                    <path d="M119.664 0H0V40H119.664V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_755_3060)">
                    <mask id="mask1_755_3060" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="40">
                        <path d="M119.7 0H0V40H119.7V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_755_3060)">
                        <path d="M110.1 0H9.5C9.2 0 8.8 0 8.4 0C8.1 0 7.8 0 7.5 0C6.8 0 6.2 0.1 5.5 0.2C4.8 0.3 4.2 0.5 3.6 0.8C3 1.1 2.5 1.5 2 2C1.5 2.5 1.1 3 0.8 3.6C0.5 4.2 0.3 4.9 0.2 5.5C0.1 6.2 0 6.9 0 7.5C0 7.8 0 8.1 0 8.4C0 8.8 0 9.1 0 9.5V30.4C0 30.8 0 31.1 0 31.5C0 31.8 0 32.1 0 32.4C0 33.1 0.1 33.7 0.2 34.4C0.3 35.1 0.5 35.7 0.8 36.3C1.1 37 1.5 37.5 2 38C2.5 38.5 3 38.9 3.6 39.2C4.2 39.5 4.8 39.7 5.5 39.8C6.2 39.9 6.8 40 7.5 40C7.8 40 8.1 40 8.4 40C8.8 40 9.1 40 9.5 40H110.1C110.5 40 110.8 40 111.2 40C111.5 40 111.8 40 112.1 40C112.8 40 113.4 39.9 114.1 39.8C114.8 39.7 115.4 39.5 116 39.2C116.6 38.9 117.1 38.5 117.6 38C118.1 37.5 118.5 37 118.8 36.4C119.1 35.8 119.3 35.2 119.4 34.5C119.5 33.8 119.6 33.2 119.6 32.5C119.6 32.2 119.6 31.9 119.6 31.6C119.6 31.2 119.6 30.9 119.6 30.5V9.5C119.6 9.1 119.6 8.8 119.6 8.4C119.6 8.1 119.6 7.8 119.6 7.5C119.6 6.8 119.5 6.2 119.4 5.5C119.3 4.8 119.1 4.2 118.8 3.6C118.5 3 118.1 2.5 117.6 2C117.1 1.5 116.6 1.1 116 0.8C115.4 0.5 114.8 0.3 114.1 0.2C113.4 0.1 112.8 0 112.1 0C111.8 0 111.5 0 111.2 0C110.9 0 110.5 0 110.1 0Z" fill="#BBBBBB"/>
                        <path d="M8.3998 39.1004C8.0998 39.1004 7.7998 39.1004 7.4998 39.1004C6.8998 39.1004 6.2998 39.1004 5.5998 38.9004C4.9998 38.8004 4.3998 38.6004 3.8998 38.4004C3.3998 38.1004 2.8998 37.8004 2.4998 37.4004C2.0998 37.0004 1.6998 36.5004 1.4998 36.0004C1.1998 35.5004 1.0998 35.0004 0.999805 34.3004C0.899805 33.6004 0.799805 32.9004 0.799805 32.4004C0.799805 32.2004 0.799805 31.5004 0.799805 31.5004V8.40039C0.799805 8.40039 0.799805 7.70039 0.799805 7.50039C0.799805 7.00039 0.799805 6.30039 0.999805 5.60039C1.1998 5.10039 1.2998 4.50039 1.5998 4.00039C1.8998 3.50039 2.1998 3.00039 2.5998 2.60039C2.9998 2.20039 3.4998 1.90039 3.9998 1.60039C4.4998 1.30039 5.0998 1.20039 5.6998 1.10039C6.2998 0.900391 6.9998 0.900391 7.4998 0.900391H8.3998H111.2H112.1C112.6 0.900391 113.3 0.900391 114 1.10039C114.6 1.20039 115.1 1.40039 115.7 1.60039C116.2 1.90039 116.7 2.20039 117.1 2.60039C117.5 3.00039 117.9 3.50039 118.1 4.00039C118.4 4.50039 118.5 5.10039 118.6 5.60039C118.7 6.20039 118.8 6.90039 118.8 7.50039C118.8 7.80039 118.8 8.10039 118.8 8.40039C118.8 8.80039 118.8 9.10039 118.8 9.50039V30.4004C118.8 30.8004 118.8 31.1004 118.8 31.5004C118.8 31.8004 118.8 32.1004 118.8 32.4004C118.8 33.0004 118.7 33.6004 118.6 34.3004C118.5 34.9004 118.3 35.5004 118.1 36.0004C117.8 36.5004 117.5 37.0004 117.1 37.4004C116.7 37.8004 116.2 38.2004 115.7 38.4004C115.2 38.7004 114.7 38.8004 114 38.9004C113.4 39.0004 112.7 39.0004 112.1 39.1004C111.8 39.1004 111.5 39.1004 111.2 39.1004H110.1H8.3998Z" fill="black"/>
                        <path d="M24.7998 20.3004C24.7998 17.5004 27.0998 16.2004 27.1998 16.1004C25.8998 14.2004 23.8998 14.0004 23.1998 13.9004C21.4998 13.7004 19.8998 14.9004 18.9998 14.9004C18.0998 14.9004 16.7998 13.9004 15.3998 13.9004C13.5998 13.9004 11.8998 15.0004 10.8998 16.6004C8.99986 19.9004 10.3998 24.9004 12.2998 27.6004C13.1998 28.9004 14.2998 30.4004 15.6998 30.4004C17.0998 30.3004 17.5998 29.5004 19.2998 29.5004C20.9998 29.5004 21.3998 30.4004 22.8998 30.4004C24.3998 30.4004 25.2998 29.1004 26.1998 27.7004C27.2998 26.2004 27.6998 24.7004 27.6998 24.6004C27.6998 24.7004 24.7998 23.6004 24.7998 20.3004Z" fill="white"/>
                        <path d="M21.9997 12.2002C22.6997 11.3002 23.2997 10.0002 23.0997 8.7002C21.9997 8.7002 20.6997 9.4002 19.8997 10.4002C19.1997 11.2002 18.5997 12.5002 18.7997 13.8002C19.9997 13.8002 21.2997 13.1002 21.9997 12.2002Z" fill="white"/>
                        <path d="M42.3 27.1006H37.6L36.5 30.5006H34.5L39 18.1006H41L45.5 30.5006H43.5L42.3 27.1006ZM38.1 25.6006H41.9L40 20.1006H39.9L38.1 25.6006Z" fill="white"/>
                        <path d="M55.2002 26.0003C55.2002 28.8003 53.7002 30.6003 51.4002 30.6003C50.1002 30.6003 49.1002 30.0003 48.6002 29.0003V33.5003H46.7002V21.5003H48.5002V23.0003C49.0002 22.0003 50.1002 21.4003 51.4002 21.4003C53.6002 21.3003 55.2002 23.2003 55.2002 26.0003ZM53.2002 26.0003C53.2002 24.2003 52.3002 23.0003 50.8002 23.0003C49.4002 23.0003 48.4002 24.2003 48.4002 26.0003C48.4002 27.8003 49.4002 29.0003 50.8002 29.0003C52.3002 29.0003 53.2002 27.8003 53.2002 26.0003Z" fill="white"/>
                        <path d="M65.0996 26.0003C65.0996 28.8003 63.5996 30.6003 61.2996 30.6003C59.9996 30.6003 58.9996 30.0003 58.4996 29.0003V33.5003H56.5996V21.5003H58.3996V23.0003C58.8996 22.0003 59.9996 21.4003 61.2996 21.4003C63.5996 21.3003 65.0996 23.2003 65.0996 26.0003ZM63.1996 26.0003C63.1996 24.2003 62.2996 23.0003 60.7996 23.0003C59.3996 23.0003 58.3996 24.2003 58.3996 26.0003C58.3996 27.8003 59.3996 29.0003 60.7996 29.0003C62.2996 29.0003 63.1996 27.8003 63.1996 26.0003Z" fill="white"/>
                        <path d="M71.7 27.0004C71.8 28.2004 73 29.0004 74.7 29.0004C76.3 29.0004 77.4 28.2004 77.4 27.1004C77.4 26.1004 76.7 25.6004 75.1 25.2004L73.5 24.8004C71.2 24.2004 70.2 23.2004 70.2 21.5004C70.2 19.4004 72.1 17.9004 74.7 17.9004C77.3 17.9004 79.1 19.4004 79.2 21.5004H77.3C77.2 20.3004 76.2 19.5004 74.7 19.5004C73.2 19.5004 72.2 20.3004 72.2 21.4004C72.2 22.3004 72.9 22.8004 74.5 23.2004L75.9 23.5004C78.4 24.1004 79.5 25.1004 79.5 26.9004C79.5 29.2004 77.6 30.7004 74.7 30.7004C71.9 30.7004 70.1 29.3004 70 27.0004H71.7Z" fill="white"/>
                        <path d="M83.3002 19.3008V21.4008H85.0002V22.9008H83.3002V27.9008C83.3002 28.7008 83.6002 29.0008 84.4002 29.0008C84.6002 29.0008 84.9002 29.0008 85.0002 29.0008V30.5008C84.8002 30.6008 84.4002 30.6008 84.0002 30.6008C82.2002 30.6008 81.5002 29.9008 81.5002 28.2008V23.0008H80.2002V21.5008H81.5002V19.4008H83.3002V19.3008Z" fill="white"/>
                        <path d="M86.0996 26.0004C86.0996 23.2004 87.7996 21.4004 90.3996 21.4004C92.9996 21.4004 94.6996 23.2004 94.6996 26.0004C94.6996 28.9004 92.9996 30.6004 90.3996 30.6004C87.6996 30.6004 86.0996 28.8004 86.0996 26.0004ZM92.7996 26.0004C92.7996 24.0004 91.8996 22.9004 90.3996 22.9004C88.8996 22.9004 87.9996 24.0004 87.9996 26.0004C87.9996 28.0004 88.8996 29.1004 90.3996 29.1004C91.8996 29.1004 92.7996 27.9004 92.7996 26.0004Z" fill="white"/>
                        <path d="M96.2002 21.4004H98.0002V23.0004C98.3002 22.0004 99.1002 21.4004 100.2 21.4004C100.5 21.4004 100.7 21.4004 100.8 21.5004V23.2004C100.7 23.1004 100.3 23.1004 100 23.1004C98.8002 23.1004 98.1002 23.9004 98.1002 25.2004V30.6004H96.2002V21.4004Z" fill="white"/>
                        <path d="M109.4 27.8008C109.2 29.4008 107.5 30.6008 105.5 30.6008C102.9 30.6008 101.2 28.8008 101.2 26.0008C101.2 23.2008 102.8 21.3008 105.4 21.3008C107.9 21.3008 109.5 23.0008 109.5 25.8008V26.4008H103.1V26.5008C103.1 28.0008 104.1 29.1008 105.5 29.1008C106.5 29.1008 107.3 28.6008 107.6 27.8008H109.4ZM103.1 25.1008H107.6C107.6 23.7008 106.7 22.8008 105.4 22.8008C104.1 22.8008 103.2 23.8008 103.1 25.1008Z" fill="white"/>
                        <path d="M37.7996 8.7002C39.5996 8.7002 40.5996 9.8002 40.5996 11.7002C40.5996 13.6002 39.5996 14.7002 37.7996 14.7002H35.5996V8.7002H37.7996ZM36.5996 13.9002H37.6996C38.8996 13.9002 39.6996 13.1002 39.6996 11.8002C39.6996 10.5002 38.9996 9.7002 37.6996 9.7002H36.5996V13.9002Z" fill="white"/>
                        <path d="M41.6992 12.4006C41.6992 10.9006 42.4992 10.1006 43.7992 10.1006C45.0992 10.1006 45.8992 11.0006 45.8992 12.4006C45.8992 13.9006 45.0992 14.7006 43.7992 14.7006C42.4992 14.8006 41.6992 13.9006 41.6992 12.4006ZM44.9992 12.4006C44.9992 11.4006 44.5992 10.9006 43.7992 10.9006C42.9992 10.9006 42.5992 11.5006 42.5992 12.4006C42.5992 13.4006 42.9992 14.0006 43.7992 14.0006C44.5992 14.0006 44.9992 13.4006 44.9992 12.4006Z" fill="white"/>
                        <path d="M51.5992 14.7002H50.6992L49.7992 11.4002H49.6992L48.7992 14.7002H47.8992L46.6992 10.2002H47.5992L48.3992 13.6002H48.4992L49.3992 10.2002H50.2992L51.1992 13.6002H51.2992L52.0992 10.2002H52.9992L51.5992 14.7002Z" fill="white"/>
                        <path d="M53.8994 10.2006H54.7994V10.9006H54.8994C55.0994 10.4006 55.5994 10.1006 56.1994 10.1006C57.1994 10.1006 57.7994 10.7006 57.7994 11.8006V14.7006H56.8994V12.0006C56.8994 11.3006 56.5994 10.9006 55.8994 10.9006C55.1994 10.9006 54.7994 11.3006 54.7994 12.0006V14.6006H53.8994V10.2006Z" fill="white"/>
                        <path d="M59.0996 8.40039H59.9996V14.7004H59.0996V8.40039Z" fill="white"/>
                        <path d="M61.1992 12.4006C61.1992 10.9006 61.9992 10.1006 63.2992 10.1006C64.5992 10.1006 65.3992 11.0006 65.3992 12.4006C65.3992 13.9006 64.5992 14.7006 63.2992 14.7006C61.9992 14.8006 61.1992 13.9006 61.1992 12.4006ZM64.5992 12.4006C64.5992 11.4006 64.1992 10.9006 63.3992 10.9006C62.5992 10.9006 62.1992 11.5006 62.1992 12.4006C62.1992 13.4006 62.5992 14.0006 63.3992 14.0006C64.0992 14.0006 64.5992 13.4006 64.5992 12.4006Z" fill="white"/>
                        <path d="M66.3994 13.4006C66.3994 12.6006 66.9994 12.1006 68.0994 12.1006L69.2994 12.0006V11.6006C69.2994 11.1006 68.9994 10.9006 68.3994 10.9006C67.8994 10.9006 67.5994 11.1006 67.4994 11.4006H66.5994C66.6994 10.6006 67.3994 10.1006 68.3994 10.1006C69.4994 10.1006 70.1994 10.7006 70.1994 11.6006V14.7006H69.2994V14.1006H69.1994C68.8994 14.6006 68.3994 14.8006 67.7994 14.8006C66.9994 14.8006 66.3994 14.2006 66.3994 13.4006ZM69.2994 13.0006V12.6006L68.1994 12.7006C67.5994 12.7006 67.2994 13.0006 67.2994 13.3006C67.2994 13.7006 67.6994 13.9006 68.0994 13.9006C68.7994 14.0006 69.2994 13.6006 69.2994 13.0006Z" fill="white"/>
                        <path d="M71.2998 12.4004C71.2998 11.0004 71.9998 10.1004 73.1998 10.1004C73.7998 10.1004 74.2998 10.4004 74.5998 10.9004H74.6998V8.40039H75.5998V14.7004H74.6998V14.0004H74.5998C74.2998 14.5004 73.7998 14.8004 73.1998 14.8004C72.0998 14.8004 71.2998 13.9004 71.2998 12.4004ZM72.2998 12.4004C72.2998 13.4004 72.7998 13.9004 73.4998 13.9004C74.1998 13.9004 74.6998 13.3004 74.6998 12.4004C74.6998 11.5004 74.1998 10.9004 73.4998 10.9004C72.6998 10.9004 72.2998 11.5004 72.2998 12.4004Z" fill="white"/>
                        <path d="M79.1992 12.4006C79.1992 10.9006 79.9992 10.1006 81.2992 10.1006C82.5992 10.1006 83.3992 11.0006 83.3992 12.4006C83.3992 13.9006 82.5992 14.7006 81.2992 14.7006C79.9992 14.8006 79.1992 13.9006 79.1992 12.4006ZM82.5992 12.4006C82.5992 11.4006 82.1992 10.9006 81.3992 10.9006C80.5992 10.9006 80.1992 11.5006 80.1992 12.4006C80.1992 13.4006 80.5992 14.0006 81.3992 14.0006C82.0992 14.0006 82.5992 13.4006 82.5992 12.4006Z" fill="white"/>
                        <path d="M84.6992 10.2006H85.5992V10.9006H85.6992C85.8992 10.4006 86.3992 10.1006 86.9992 10.1006C87.9992 10.1006 88.5992 10.7006 88.5992 11.8006V14.7006H87.6992V12.0006C87.6992 11.3006 87.3992 10.9006 86.6992 10.9006C85.9992 10.9006 85.5992 11.3006 85.5992 12.0006V14.6006H84.6992V10.2006Z" fill="white"/>
                        <path d="M93.4998 9.10059V10.2006H94.4998V11.0006H93.4998V13.3006C93.4998 13.8006 93.6998 14.0006 94.0998 14.0006C94.1998 14.0006 94.2998 14.0006 94.3998 14.0006V14.7006C94.2998 14.7006 94.0998 14.7006 93.8998 14.7006C92.8998 14.7006 92.4998 14.4006 92.4998 13.5006V11.0006H91.7998V10.3006H92.4998V9.10059H93.4998Z" fill="white"/>
                        <path d="M95.6992 8.40039H96.5992V10.9004H96.6992C96.8992 10.4004 97.3992 10.1004 98.0992 10.1004C99.0992 10.1004 99.6992 10.7004 99.6992 11.8004V14.7004H98.7992V12.0004C98.7992 11.3004 98.4992 10.9004 97.7992 10.9004C97.0992 10.9004 96.6992 11.4004 96.6992 12.0004V14.6004H95.7992V8.40039H95.6992Z" fill="white"/>
                        <path d="M104.799 13.5006C104.599 14.3006 103.899 14.8006 102.799 14.8006C101.499 14.8006 100.699 13.9006 100.699 12.5006C100.699 11.1006 101.499 10.1006 102.799 10.1006C104.099 10.1006 104.799 11.0006 104.799 12.4006V12.7006H101.599C101.599 13.5006 102.099 14.0006 102.799 14.0006C103.299 14.0006 103.699 13.8006 103.899 13.5006H104.799ZM101.599 12.0006H103.899C103.899 11.3006 103.399 10.8006 102.799 10.8006C102.099 10.9006 101.699 11.3006 101.599 12.0006Z" fill="white"/>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_755_3060">
                    <rect width="120" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
