import {Button, Chip} from "@nextui-org/react";
import {AppStoreLogo} from "../../assets/AppStoreLogo";
import {GooglePlayLogo} from "../../assets/GooglePlayLogo";
import MainImage from '../../assets/MainImage.webp';
import MainMock from '../../assets/MainMock.webp';
import Card1Img1 from '../../assets/card1img1.webp';
import Card1Img2 from '../../assets/card1img2.webp';
import Card2Img1 from '../../assets/card2img1.webp';
import Card3Img1 from '../../assets/card3img1.webp';
import Card2Img2 from '../../assets/Phone.webp';
import Card1 from '../../assets/cards/card1.webp';
import Card2 from '../../assets/cards/card2.webp';
import Card3 from '../../assets/cards/card3.webp';
import Card4 from '../../assets/cards/card4.webp';
import Card5 from '../../assets/cards/card5.webp';
import Card6 from '../../assets/cards/card6.webp';
import Card1k from '../../assets/cards/card1k.webp';
import Card2k from '../../assets/cards/card2k.webp';
import Card3k from '../../assets/cards/card3k.webp';
import Card4k from '../../assets/cards/card4k.webp';
import Card5k from '../../assets/cards/card5k.webp';
import Card6k from '../../assets/cards/card6k.webp';
import unsplash from '../../assets/mocks/unsplash.webp';
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import axios from "axios";
import {AppStoreIcon} from "../../assets/AppStoreIcon";
import {GooglePlayIcon} from "../../assets/GooglePlayIcon";
export const Main = () => {
    const lang = localStorage.getItem("lang");

    const l = {
        "ru": {
            "text1": {
                "label": "Станьте нашим",
                "color": "white"
            },
            "text2": {
                "label": "партнером",
                "color": "#BDFF00"
            },
            "text3": {
                "label": "Добавьте ваше заведение в экосистему sorpa Получите самые выгодные условия для вашего бизнеса",
                "color": "black"
            },
            "text4": {
                "label": "Подать заявку",
                "color": "white"
            },
            "text5": {
                "label": "О системе",
                "color": "black"
            },
            "text6": {
                "label": "Sorpa",
                "color": "#BDFF00"
            },
            "text62": {
                "label": "экоcистема",
                "color": "black"
            },
            "text7": {
                "label": "Это целая экосистема лояльности, которая объединяет предпринимателей и клиентов в одно большое сообщество, где каждый получает свою",
                "color": "black"
            },
            "text8": {
                "label": "добавку",
                "color": "black"
            },
            "text9": {
                "label": "Скачать презентацию",
                "color": "black"
            },
            "text10": {
                "label": "sorpa",
                "color": "black"
            },
            "text11": {
                "label": "Каталог предприятий Онлайн кошелек для оплаты",
                "color": "black"
            },
            "text12": {
                "label": "sorpa pay",
                "color": "black"
            },
            "text13": {
                "label": "Вход для сотрудников\n" +
                    "Прием платежей\n" +
                    "История платежных чеков",
                "color": "black"
            },
            "text14": {
                "label": "sorpa business",
                "color": "black"
            },
            "text15": {
                "label": "Эффективное управление филиалами\n" +
                    "Управление командой и персоналом\n" +
                    "Подробные отчеты по оплатам",
                "color": "black"
            },
            "text16": {
                "label": "Что предоставляет sorpa",
                "color": "black"
            }
        },
        "kk": {
            "text1": {
                "label": "Біздің",
                "color": "white"
            },
            "text2": {
                "label": "Серіктестеріміз",
                "color": "#BDFF00"
            },
            "text22": {
                "label": "болыңыз",
                "color": "white"
            },
            "text3": {
                "label": "Сіздің мекемеңізді Sorpa экожүйесіне қосыңыз Бизнесіңізге ең тиімді шарттарды алыңыз",
                "color": "white"
            },
            "text4": {
                "label": "Өтінім беру",
                "color": "black"
            },
            "text5": {
                "label": "Жүйе туралы",
                "color": "white"
            },
            "text6": {
                "label": "Sorpa",
                "color": "#BDFF00"
            },
            "text62": {
                "label": "экожүйесі",
                "color": "black"
            },
            "text7": {
                "label": "Бұл – кәсіпкерлер мен клиенттерді бір үлкен қауымдастыққа біріктіретін толыққанды адалдық экожүйесі, мұнда әркім өз қосымшасын алады",
                "color": "black"
            },
            "text8": {
                "label": "қосымшасын",
                "color": "#BDFF00"
            },
            "text9": {
                "label": "Презентацияны жүктеу",
                "color": "black"
            },
            "text10": {
                "label": "sorpa",
                "color": "black"
            },
            "text11": {
                "label": "Кәсіпорындар каталогы Төлем жасауға арналған онлайн әмиян\n" +
                    "Қосымшаның есептелуі",
                "color": "black"
            },
            "text12": {
                "label": "sorpa pay",
                "color": "black"
            },
            "text13": {
                "label": "Қызметкерлерге арналған кіру\n" +
                    "Төлемдерді қабылдау\n" +
                    "Төлем чектерінің тарихы",
                "color": "black"
            },
            "text14": {
                "label": "sorpa business",
                "color": "black"
            },
            "text15": {
                "label": "Филиалдарды тиімді басқару\n" +
                    "Команда мен персоналды басқару\n" +
                    "Төлемдер бойынша толық есептер",
                "color": "black"
            },
            "text16": {
                "label": "Sorpa не береді ?",
                "color": "black"
            }
        }
    }
    return(
        <div>
            <section className={"container"} id={""}>
                <div className={"w-full relative z-40"}>
                    <div className={"hero-back w-full mb-[40px] rounded-[24px] p-[24px] text-white flex flex-col min-h-[540px] justify-between relative z-50 h-[570px] md:h-[690px] overflow-hidden"}>
                        <img src={MainImage} className={"absolute z-30 bottom-0 md:right-0 w-[520px] hidden lg:flex"}/>
                        <img src={MainMock} className={"absolute z-30 bottom-0 left-0 w-[220px]"}/>
                        <div className={"flex flex-col gap-[16px] mt-[60px] relative z-50"}>
                            <h1 className={"text-[40px] text-white font-[600] leading-[56px]"}>{l[lang]?.text1?.label}  {lang === "ru" && <br/>} <span className={"text-[#BDFF00]"}> {l[lang]?.text2?.label}</span> {lang === "kk" && <br/>}{lang === "kk" && l[lang]?.text22?.label}</h1>
                            <p className={"max-w-[500px]"}>{l[lang]?.text3?.label}</p>
                            <div className={"flex w-full max-w-[500px] justify-between gap-[16px] flex-col md:flex-row"}>
                                <a href={"/signup"} className={" w-full"}><Button className={"text-black bg-[#BDFF00] font-[600] w-full"}>{l[lang]?.text4?.label}</Button></a>
                                <a href={"/#about"} className={" w-full"}><Button className={"text-black bg-[#BDFF00] font-[600] w-full"}>{l[lang]?.text5?.label}</Button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={'about'} className={"py-[40px] md:py-[80px]"} style={{width:"100%", background:"#222222", color:"white"}}>
                <div className={"container"}>
                    <div className={"flex flex-col items-center gap-[36px]"}>
                        {lang === "ru" ? <h2 className={"text-center text-[32px] font-[600]"}>{l[lang]?.text62?.label} <span className={"text-[#BDFF00]"}>{l[lang]?.text6?.label}</span></h2> :
                            <h2 className={"text-center text-[32px] font-[600]"}><span className={"text-[#BDFF00]"}>{l[lang]?.text6?.label}</span> {l[lang]?.text62?.label}</h2>
                        }
                        {lang === "ru" ? <p className={"max-w-[600px] text-center"}>Это целая экосистема лояльности, которая объединяет <br className={"hidden md:flex"}/> предпринимателей и клиентов в одно большое сообщество, <br className={"hidden md:flex"}/>где каждый получает свою <span className={"text-[#BDFF00]"}>добавку</span></p> :
                            <p className={"max-w-[600px] text-center"}>Бұл – кәсіпкерлер мен клиенттерді бір үлкен қауымдастыққа біріктіретін толыққанды адалдық экожүйесі, мұнда әркім өз <br className={"hidden md:flex"}/> <span className={"text-[#BDFF00]"}>қосымшасын</span> алады</p>
                        }
                        <a href={"/Sorpa.pdf"} download={true}><Button className={"text-black bg-[#BDFF00] font-[600] md:w-[280px]"}>{l[lang]?.text9?.label}</Button></a>
                    </div>
                    <div className={"flex flex-col md:flex-row w-full gap-[12px] mt-[24px]"}>
                        <div className={"card-back p-[24px] rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <h3 className={"text-[24px] font-[600]"}>sorpa</h3>
                            <p className={"mt-[12px] max-w-[240px] z-40 relative"}>{l[lang]?.text11?.label}</p>
                            <div className={"flex justify-between items-center relative mt-[70px] w-full"}>
                                <img src={Card1Img2} className={"object-contain w-[120px] absolute left-[-24px]"}/>
                                <img src={Card1Img1} className={"object-contain w-[160px] absolute right-[-14px] bottom-[-90px] rounded-[24px] z-30"}/>
                            </div>
                        </div>
                        <div className={"card-back p-[24px] rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <h3 className={"text-[24px] font-[600]"}>sorpa pay</h3>
                            <p className={"mt-[12px] max-w-[240px] relative z-40"}>{l[lang]?.text13?.label}</p>
                            <div className={"flex justify-between items-center relative mt-[70px] w-full"}>
                                <img src={Card2Img1} className={"object-contain w-[85px] absolute left-[-12px] bottom-[-36px] z-20"}/>
                                <img src={Card2Img2} className={"object-contain w-[160px] absolute right-[-12px] rounded-[24px] z-20"}/>
                            </div>
                        </div>
                        <div className={"card-back p-[24px] rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <h3 className={"text-[24px] font-[600]"}>sorpa business</h3>
                            <p className={"mt-[12px] relative z-40 max-w-[240px]"}>{l[lang]?.text15?.label}</p>
                            <div className={"flex justify-between items-center relative mt-[70px] w-full"}>
                                <img src={Card3Img1} className={"object-contain w-[95px] absolute left-[-12px] bottom-[-46px]"}/>
                                <img src={MainImage} className={"object-contain w-[190px] absolute right-[-24px] rounded-[24px] z-30"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={'about'} className={"py-[40px] md:py-[80px]"} style={{width:"100%", color:"white"}}>
                <div className={"container"}>
                    <div className={"flex flex-col items-center gap-[36px] w-full"}>
                        {
                            lang === "ru" ?
                                <h2 className={"text-[32px] font-[600] w-full text-left"}>Что предоставляет <span className={"text-[#BDFF00]"}>sorpa</span></h2> :
                                <h2 className={"text-[32px] font-[600] w-full text-left"}><span className={"text-[#BDFF00]"}>Sorpa</span> не береді ?</h2>
                        }
                    </div>
                    <div className={"flex flex-col md:flex-row w-full gap-[12px] mt-[24px]"}>
                        <div className={"card-back rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <img src={lang === "ru" ? Card1 : Card1k} className={"object-cover w-full h-full"}/>
                        </div>
                        <div className={"card-back rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <img src={lang === "ru" ? Card2 : Card2k} className={"object-cover w-full h-full"}/>
                        </div>
                        <div className={"card-back rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <img src={lang === "ru" ? Card3 : Card3k} className={"object-cover w-full h-full"}/>
                        </div>
                    </div>
                    <div className={"flex flex-col md:flex-row w-full gap-[12px] mt-[24px]"}>
                        <div className={"card-back rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <img src={lang === "ru" ? Card4 : Card4k} className={"object-cover w-full h-full"}/>
                        </div>
                        <div className={"card-back rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <img src={lang === "ru" ? Card5 : Card5k} className={"object-cover w-full h-full"}/>
                        </div>
                        <div className={"card-back rounded-[24px] relative min-h-[300px] w-full overflow-hidden"}>
                            <img src={lang === "ru" ? Card6 : Card6k} className={"object-cover w-full h-full"}/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
