import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    Select,
    SelectItem
} from "@nextui-org/react";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router";

export const SignUp = () => {
    const lang = localStorage.getItem("lang");

    const l = {
        "ru": {
            "text1": {
                "label": "Регистрация",
                "color": "black"
            },
            "text2": {
                "label": "Наименование компании",
                "color": "black"
            },
            "text3": {
                "label": "Укажите название вашей компании",
                "color": "white"
            },
            "text21": {
                "label": "ФИО Руководителя",
                "color": "black"
            },
            "text31": {
                "label": "Укажите ФИО руководителя",
                "color": "white"
            },
            "text4": {
                "label": "Контактный номер",
                "color": "black"
            },
            "text5": {
                "label": "Укажите номер в формате +77771234567",
                "color": "black"
            },
            "text6": {
                "label": "Электронная почта",
                "color": "black"
            },
            "text7": {
                "label": "Город",
                "color": "black"
            },
            "text8": {
                "label": "Категория",
                "color": "black"
            },
            "text9": {
                "label": "Зарегистрироваться",
                "color": "black"
            }
        },
        "kk": {
            "text1": {
                "label": "Тіркеy",
                "color": "black"
            },
            "text2": {
                "label": "Компания атауы",
                "color": "black"
            },
            "text3": {
                "label": "Компанияңыздың атын енгізіңіз",
                "color": "white"
            },
            "text21": {
                "label": "Басшының толық аты-жөні",
                "color": "black"
            },
            "text31": {
                "label": "Басшының аты-жөнін жазыңыз",
                "color": "white"
            },
            "text4": {
                "label": "Байланыс нөмірі",
                "color": "black"
            },
            "text5": {
                "label": "Нөмірді +77771234567 форматында енгізіңіз",
                "color": "black"
            },
            "text6": {
                "label": "Электрондық пошта",
                "color": "black"
            },
            "text7": {
                "label": "Қала",
                "color": "black"
            },
            "text8": {
                "label": "Санат",
                "color": "black"
            },
            "text9": {
                "label": "Тіркелу",
                "color": "black"
            }
        }
    }

    const history = useNavigate();
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [cities, setCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sendData, setSendData] = useState({
        name: '',
        head_name: '',
        phone_number: '',
        email: '',
        city: '',
        category: ''
    })
    useEffect(() => {
        axios.get("https://backend.sorpa.com/places/landing/signup/").then(
            res => {
                setCities(res.data.cities);
                setCategories(res.data.categories);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);
    const Submit = () => {
        axios.post('https://backend.sorpa.com/places/landing/signup/', {...sendData}).then(
            res => {
                setSendData({
                        name: '',
                        head_name: '',
                        phone_number: '',
                        email: '',
                        category: '',
                        city: ''
                    });
                setSuccess(true);
            }
        ).catch(
            err => {
                setError(err.response.data.message);
            }
        )
        console.log(sendData);
    }
    return (
        <div className={"container"}>
            <div style={{
                width: "100%",
                maxWidth: 600,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16
            }}>
                <h2 style={{fontWeight: 600, fontSize: 32, textAlign: "left", marginTop: 20}}>{l[lang]?.text1?.label}</h2>
                <p style={{width: "100%", paddingLeft: 4, paddingRight: 4, fontSize: 14, color: "crimson"}}>{error}</p>
                <Input
                    type={"text"}
                    label={l[lang]?.text2?.label}
                    placeholder={l[lang]?.text3?.label}
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    value={sendData.name}
                    onChange={(event) => {
                        setSendData({...sendData, name: event.target.value});
                        setError("");
                    }}
                />
                <Input
                    type={"text"}
                    label={l[lang]?.text21?.label}
                    placeholder={l[lang]?.text31?.label}
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    value={sendData.head_name}
                    onChange={(event) => {
                        setSendData({...sendData, head_name: event.target.value});
                        setError("");
                    }}
                />
                <Input
                    type={"text"}
                    label={l[lang]?.text4?.label}
                    placeholder={l[lang]?.text5?.label}
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    value={sendData.phone_number}
                    onChange={(event) => {
                        setSendData({...sendData, phone_number: event.target.value});
                        setError("");
                    }}
                />
                <Input
                    type={"text"}
                    label="Email"
                    placeholder={l[lang]?.text6?.label}
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    value={sendData.email}
                    onChange={(event) => {
                        setSendData({...sendData, email: event.target.value});
                        setError("");
                    }}
                />
                <Select
                    label={l[lang]?.text7?.label}
                    value={sendData.city}
                    onChange={(items) => {
                        setSendData({...sendData, city:items.target.value});
                        setError("");
                    }}
                    listboxProps={{
                        itemClasses: {
                            base: "wrapper-content"
                        },
                    }}
                    popoverProps={{
                        classNames: {
                            content: "wrapper",
                        },
                    }}
                    color={"secondary"}
                    size={"sm"}
                    style={{borderRadius: 16, background: '#7676803D', color: "white"}}
                    classNames={{
                        label: "text-white",
                    }}
                >
                    {cities.map((city) => (
                        <SelectItem key={city.id} value={city.name}>
                            {city.id}
                        </SelectItem>
                    ))}
                </Select>
                <Select
                    label={l[lang]?.text8?.label}
                    value={sendData.city}
                    onChange={(items) => {
                        setSendData({...sendData, category:items.target.value});
                        setError("");
                    }}
                    listboxProps={{
                        itemClasses: {
                            base: "wrapper-content"
                        },
                    }}
                    popoverProps={{
                        classNames: {
                            content: "wrapper",
                        },
                    }}
                    color={"secondary"}
                    size={"sm"}
                    style={{borderRadius: 16, background: '#7676803D', color: "white"}}
                    classNames={{
                        label: "text-white",
                    }}
                >
                    {categories.map((category) => (
                        <SelectItem key={category.id} value={category.name}>
                            {category.id}
                        </SelectItem>
                    ))}
                </Select>
                <Button disabled={isLoading} onPress={Submit} style={{background: "#BF0", fontWeight: 600, borderRadius: 12}}
                        fullWidth={true}>{l[lang]?.text9?.label}</Button>
                <Modal isOpen={success} hideCloseButton={true}>
                    <ModalContent style={{background: "#BF0", padding: 20, display: "flex", gap: 16, color: "black"}}>
                        <ModalHeader className="flex flex-col gap-1 text-center">{lang === "ru" ? "Заявка была создана" : "Қолданба жасалды"}</ModalHeader>
                        <ModalBody>
                            <p>{lang === "ru" ? "Менеджер свяжется с вами в ближайшее время" : "Менеджер сізге жақын арада хабарласады"}</p>
                            <Button style={{background: "white", fontWeight: "500", fontSize: 16}} onPress={() => {
                                setSuccess(false);
                                history('/');
                            }}>{lang === "ru" ? "На главную" : "Басты бетке"}</Button>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </div>
    )
}
